import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">


<path d="M0 5400 l0 -5400 5400 0 5400 0 0 5400 0 5400 -5400 0 -5400 0 0
-5400z m5920 5375 c69 -8 166 -24 215 -36 148 -34 388 -122 494 -181 31 -17
76 -42 101 -56 52 -28 206 -132 235 -159 11 -10 43 -36 70 -58 71 -57 246
-238 242 -250 -2 -6 -47 -11 -105 -13 l-102 -3 -67 65 c-130 123 -246 207
-419 304 -191 107 -417 180 -669 217 -203 30 -503 14 -700 -36 -113 -28 -259
-77 -333 -110 -35 -16 -65 -29 -67 -29 -8 0 -248 -141 -255 -150 -3 -3 -41
-32 -85 -65 -72 -53 -261 -232 -310 -295 -175 -221 -279 -412 -359 -659 -65
-200 -87 -351 -87 -586 0 -264 31 -446 117 -677 164 -443 476 -805 889 -1033
65 -36 235 -112 305 -136 100 -36 323 -84 345 -75 8 3 68 115 134 248 65 134
215 439 333 678 118 239 275 557 348 705 73 149 182 368 241 488 60 121 109
228 109 238 0 19 -17 20 -70 2 -8 -2 -109 -29 -225 -59 -201 -51 -458 -119
-537 -142 -22 -6 -40 -7 -43 -1 -7 11 3 42 139 399 47 124 86 229 86 233 0 5
6 23 14 40 35 81 122 310 262 687 63 168 78 200 89 188 3 -2 1 -121 -4 -264
-4 -142 -6 -265 -4 -271 4 -10 191 -13 871 -15 585 -2 866 -6 869 -13 2 -8
-124 -180 -210 -285 -7 -8 -23 -31 -37 -50 -24 -34 -34 -47 -77 -97 -23 -28
-23 -26 20 -153 55 -164 97 -438 97 -640 0 -449 -151 -897 -424 -1264 -79
-107 -229 -269 -311 -337 -201 -166 -341 -251 -598 -364 -126 -55 -326 -106
-532 -134 -94 -14 -310 -19 -321 -9 -8 8 29 65 80 124 l32 37 135 14 c229 22
403 67 599 153 90 39 183 86 205 103 6 4 53 35 105 68 52 34 97 64 100 68 3 3
32 28 65 55 75 61 212 203 268 276 23 30 47 61 54 68 15 16 110 169 151 244
37 69 132 305 132 329 0 10 4 20 9 23 5 3 11 18 14 33 2 16 14 73 25 128 24
116 42 286 42 396 0 194 -66 569 -101 569 -8 0 -90 -104 -229 -290 -36 -48
-90 -119 -120 -157 -57 -73 -263 -341 -385 -500 -38 -50 -151 -196 -250 -325
-99 -128 -200 -260 -224 -293 -25 -33 -50 -66 -58 -73 -7 -7 -52 -66 -100
-130 -48 -64 -92 -122 -98 -128 -5 -6 -48 -62 -95 -124 -47 -62 -112 -147
-146 -189 -53 -66 -168 -218 -229 -301 -33 -46 -147 -193 -174 -226 -43 -52
-55 -68 -81 -104 -13 -19 -27 -37 -30 -40 -3 -3 -25 -32 -50 -65 -25 -33 -49
-65 -55 -71 -5 -6 -30 -38 -55 -72 -24 -34 -48 -58 -52 -54 -4 4 44 110 106
235 86 174 109 230 100 238 -7 6 -60 21 -119 34 -102 23 -227 62 -290 90 -202
91 -241 111 -385 204 -325 209 -649 598 -775 931 -15 41 -31 80 -35 85 -4 6
-16 40 -28 76 -115 348 -137 732 -63 1114 57 294 207 612 411 872 252 320 656
601 1002 698 25 8 55 18 66 24 27 14 261 60 362 70 138 14 357 12 490 -4z
m-2140 -885 c0 -5 -15 -33 -34 -62 -18 -29 -63 -114 -100 -188 -37 -74 -72
-135 -78 -135 -11 0 -66 87 -123 195 -16 30 -37 66 -46 80 -28 41 -59 100 -59
110 0 6 85 10 220 10 137 0 220 -4 220 -10z m1450 -1 c0 -6 -7 -36 -17 -67 -9
-32 -42 -156 -73 -277 -32 -121 -67 -254 -79 -295 -11 -41 -48 -178 -80 -305
-33 -126 -67 -255 -76 -285 -9 -30 -37 -131 -62 -225 -24 -93 -49 -185 -54
-202 -24 -84 -28 -108 -19 -117 10 -10 34 -6 205 42 61 17 144 39 185 50 41
11 131 37 200 57 143 42 269 75 289 75 15 0 13 -21 -14 -115 -9 -33 -28 -105
-41 -160 -48 -193 -66 -261 -106 -415 -43 -165 -92 -366 -108 -440 -6 -25 -21
-85 -35 -135 -14 -49 -30 -110 -36 -133 -7 -24 -18 -46 -26 -49 -26 -10 -216
45 -235 68 -10 11 -43 64 -74 117 -96 165 -189 323 -250 426 -33 55 -75 127
-94 161 -45 81 -156 271 -199 343 -51 85 -99 169 -139 242 -19 36 -38 67 -42
70 -6 5 -90 149 -90 155 0 2 -22 39 -49 82 -26 43 -60 101 -75 128 -14 28 -36
66 -48 86 -13 20 -47 78 -77 129 l-53 92 27 116 c56 239 164 465 319 672 22
30 55 69 73 87 l32 33 461 0 c327 0 460 -3 460 -11z m3401 -4480 c128 -13 195
-31 247 -69 47 -33 83 -103 90 -171 l4 -48 -132 -3 c-136 -3 -160 1 -160 30 0
28 -43 69 -85 81 -52 14 -398 14 -464 0 -28 -6 -62 -20 -76 -31 -49 -39 -58
-71 -63 -231 -8 -250 16 -307 135 -324 74 -10 396 -11 445 0 52 11 96 51 108
98 18 66 13 68 -184 71 l-171 3 0 75 0 75 320 0 320 0 0 -160 c-1 -143 -3
-165 -22 -205 -28 -59 -56 -84 -123 -110 -47 -18 -87 -23 -290 -31 -522 -20
-726 30 -802 197 -20 44 -22 69 -26 242 -4 204 1 250 40 332 49 103 133 147
333 175 113 16 420 18 556 4z m1507 -1 c19 -19 16 -173 -3 -192 -14 -13 -73
-14 -498 -9 -333 4 -484 9 -489 17 -13 19 -9 171 4 184 17 17 969 17 986 0z
m-8645 -35 c12 -21 30 -54 39 -73 222 -471 309 -641 326 -638 10 2 82 139 189
358 95 195 177 363 183 373 9 15 28 17 155 17 80 0 145 -3 145 -6 0 -3 -15
-36 -34 -73 -19 -36 -82 -165 -141 -286 -207 -426 -280 -570 -290 -577 -5 -4
-103 -8 -218 -8 l-207 0 -49 103 c-27 56 -97 201 -156 322 -216 444 -245 504
-245 515 0 6 49 10 140 10 l140 0 23 -37z m2152 8 c96 -37 166 -90 195 -146
24 -48 25 -57 28 -267 4 -274 -2 -311 -66 -378 -50 -51 -81 -69 -171 -103 -51
-19 -83 -21 -348 -25 -325 -4 -401 3 -496 47 -71 32 -118 76 -150 140 -20 43
-22 62 -25 261 -4 261 2 297 63 366 45 52 135 105 211 123 22 6 183 9 369 7
319 -2 332 -3 390 -25z m719 12 c4 -9 6 -174 6 -366 -1 -192 2 -353 7 -358 5
-5 153 -10 328 -11 l320 -3 0 -95 0 -95 -469 -3 c-369 -2 -471 0 -478 10 -9
16 -11 922 -1 931 3 4 68 7 144 7 118 0 138 -2 143 -17z m1831 -83 l0 -95
-199 -3 c-127 -1 -204 -6 -213 -13 -10 -9 -13 -85 -13 -375 l0 -365 -142 3
-143 3 -3 359 c-1 245 -6 364 -13 373 -9 10 -54 13 -194 13 -101 0 -190 3
-199 6 -13 5 -16 23 -16 93 0 48 3 91 7 94 3 4 259 6 567 5 l561 -3 0 -95z
m922 88 c10 -13 79 -144 233 -443 45 -88 120 -233 166 -321 70 -134 81 -163
68 -168 -31 -12 -261 -7 -272 7 -5 6 -24 39 -42 72 -56 104 -22 96 -364 93
l-301 -3 -45 -85 -45 -85 -134 -3 c-74 -1 -141 0 -148 2 -8 4 33 95 131 288
206 407 311 610 327 636 14 22 17 22 215 22 149 0 204 -3 211 -12z m2988 -453
l0 -80 -335 -5 -335 -5 -3 -88 c-2 -57 1 -94 9 -103 9 -12 72 -14 354 -14
l343 0 6 -22 c8 -33 7 -141 -2 -156 -7 -10 -113 -12 -498 -10 l-489 3 -3 270
c-1 148 0 275 3 282 3 11 101 13 477 11 l473 -3 0 -80z m-1606 -1045 c33 -9
101 -50 101 -60 0 -3 -16 -22 -34 -43 l-34 -37 -38 25 c-47 32 -84 32 -123 -1
-39 -33 -51 -77 -51 -194 1 -158 42 -217 143 -207 28 3 62 8 73 12 19 6 24 0
37 -39 15 -45 15 -46 -6 -58 -13 -8 -63 -13 -127 -13 -89 0 -112 3 -150 22
-55 28 -103 96 -120 172 -16 67 -8 198 15 264 43 122 187 194 314 157z m519
-5 c67 -20 113 -61 143 -127 21 -46 24 -68 24 -168 0 -99 -3 -122 -23 -165
-49 -106 -129 -155 -252 -155 -123 0 -195 44 -242 145 -20 43 -23 66 -23 160
0 99 3 116 27 165 41 85 73 114 150 141 65 23 128 24 196 4z m1380 -15 c28
-12 52 -27 52 -32 0 -9 -21 -50 -38 -76 -6 -9 -18 -7 -52 9 -75 36 -150 25
-150 -23 0 -38 22 -59 108 -102 102 -51 133 -87 140 -158 9 -92 -47 -180 -134
-208 -54 -18 -158 -7 -226 23 -32 14 -58 29 -58 32 0 5 39 86 45 93 1 1 31
-11 66 -28 35 -16 73 -30 84 -30 29 0 62 26 69 54 12 48 -8 73 -96 116 -46 23
-91 49 -102 58 -44 40 -61 145 -31 202 17 33 66 72 110 89 46 16 152 7 213
-19z m-3653 -12 c-14 -69 -11 -67 -83 -70 l-67 -3 -5 -254 -5 -254 -67 -1 -68
-2 -2 256 -3 255 -72 3 -73 3 0 49 0 50 226 0 226 0 -7 -32z m460 -18 l0 -45
-120 -5 -120 -5 -3 -64 c-2 -44 1 -67 10 -73 7 -5 51 -7 98 -6 l85 2 -1 -36
c-2 -67 -4 -68 -104 -68 l-91 0 3 -77 3 -78 125 -5 125 -5 0 -48 0 -48 -195 0
-195 0 -3 306 -2 305 192 -2 193 -3 0 -45z m235 -205 l0 -255 105 0 105 0 0
-52 0 -53 -177 2 -178 3 -3 292 c-1 161 0 298 3 305 3 9 27 13 75 13 l70 0 0
-255z m650 205 l0 -49 -117 -3 -118 -3 -3 -64 c-2 -44 1 -67 10 -72 6 -4 51
-7 98 -7 l85 1 -3 -39 c-5 -65 -3 -64 -102 -64 l-91 0 3 -77 3 -78 124 -5 124
-5 0 -37 c-1 -21 -2 -44 -2 -50 -1 -10 -47 -13 -196 -13 l-195 0 0 308 0 307
190 0 190 0 0 -50z m1414 -117 c36 -93 77 -197 90 -233 l24 -65 16 37 c9 21
16 41 16 45 1 4 34 91 74 193 l74 185 60 3 60 3 6 -53 c6 -53 23 -362 28 -492
l3 -70 -63 1 c-35 1 -65 3 -68 5 -2 3 -4 75 -5 162 0 98 -4 156 -10 154 -6 -2
-38 -73 -71 -158 -34 -85 -62 -156 -63 -158 -1 -1 -26 -3 -56 -5 -54 -2 -54
-2 -73 38 -16 34 -73 175 -108 265 -17 45 -25 4 -27 -148 -1 -80 -4 -149 -7
-151 -5 -5 -117 -1 -123 4 -4 5 29 574 34 593 3 8 25 12 64 12 l59 0 66 -167z"/>
<path d="M3028 5228 c-51 -14 -102 -67 -118 -121 -8 -29 -11 -95 -8 -193 3
-143 4 -151 31 -191 15 -25 44 -51 68 -64 39 -19 54 -21 243 -17 174 3 206 6
236 22 85 46 103 99 98 287 -4 182 -17 213 -109 261 -41 21 -58 23 -224 25
-100 1 -197 -3 -217 -9z"/>
<path d="M6893 5218 c-5 -7 -27 -49 -49 -93 -21 -44 -41 -82 -44 -85 -12 -10
-90 -171 -90 -184 0 -8 7 -17 16 -20 20 -8 358 -8 378 0 22 8 20 28 -8 72 -20
33 -52 93 -131 252 -20 39 -44 70 -55 70 -4 0 -12 -6 -17 -12z"/>
<path d="M8859 3795 c-43 -24 -60 -79 -60 -197 0 -151 38 -218 123 -218 83 0
127 96 114 248 -6 80 -23 128 -54 155 -28 25 -90 31 -123 12z"/>
</g>
</svg>



    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
